const EnumTipoMedicaoControle = Object.freeze(
    [
        {
            value: 'ADMINISTRATIVA',
            label: 'Administrativa'
        },
        {
            value: 'ENGENHARIA',
            label: 'Engenharia'
        }
    ]
  )
  
  export default EnumTipoMedicaoControle;